.fc-feedback-container {
    display: flex;
    flex-direction: column;
    padding: 1vh 3vw;
    margin: 0.5vh 2vw;
    background-color: var(--secondary-color);
    color: var(--color-color);
    font-size: 16px;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}

/* @media (max-width: 768px) {
    .fc-feedback-container {
        padding: 1vh 1vw;
        margin: 0.5vh 0.5vw;
    }
}

@media (min-width: 1024px) {
    .fc-feedback-container {
        justify-content: space-between;
        padding: 1vh 3vw;
        margin: 0.5vh 2vw;
    }
} */

.fc-feedback-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    padding: 0;
    margin-bottom: .5vh;
    color: var(--primary-color);
}

.fc-feedback-subtitle {
    font-size: 1rem;
    text-align: left;
    padding:0;
    margin-bottom: .5vh;
    color: var(--primary-color);
}

.fc-feedback-input-group {
    margin-bottom: 2vh;
    text-align: left;
}

.fc-feedback-input-group input {

    height: .5rem;
    padding: 2%;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    resize: vertical;
}
.fc-feedback-input-group textarea {
    width: 94%;
    height: max-content;
    padding: 2%;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    resize: vertical;
}

.fc-feedback-thankyou-message {
    font-size: 1rem;
    text-align: left;
    padding:0;
    margin-bottom: .5vh;
    color: var(--primary-color);
}

.fc-feedback-submit-button-group{
    display: flex;
    justify-content: right;
    gap: 4rem;
}


.fc-feedback-submit-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid;
    padding: 10px;
    border-radius: 5rem;
    margin-right: 5%;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Shadow for send button */
}

.fc-feedback-submit-button:hover {
    border:1px solid var(--primary-color);
    background-color: var(--secondary-color);
    color: var(--primary-color);
}


.toggle-switch {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.toggle-switch input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-switch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-switch label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggle-switch input:checked + label {
    background: #4cd137;
}

.toggle-switch input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-switch label:active:after {
    width: 45px;
}

