.carouselItem {
    transform: scale(.95); 
    text-align: center;
    border-radius: 10px; /* Optional: Rounds the corners of each carousel item */
    background: var(--primary-color); /* Optional: Sets a white background */
     /* Optional: Adds a subtle shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition for hover effects */
    display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; 
}

.carouselItem:hover {
    transform: scale(1.0); 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.custom-prev-arrow {
    left: 5.5%;
    top: 34%;
    z-index: 1; /* Ensure it's clickable */
    display: block;
    transform: scale(1.35); 
}

.custom-next-arrow {
    right: 5.5%; /* Adjust as needed to position the arrow to the right of the slider */
    top: 34%;
    z-index: 1; /* Ensure it's clickable */
    display: block;
    transform: scale(1.35); 
}
.image-container {
    display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; 
    width: 100%; /* Full width of the container */
    margin: 2vw auto; /* Centers the image container with vertical spacing */
    overflow: hidden; /* Ensures nothing overflows from the container */
    border-radius: 10px; /* Rounds the corners of the image container */
}

.appImage {
    width: 85%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the area */
    border-radius: 10px; /* Rounds the corners of the image */
}

.appName {
    font-size: 1.5rem; /* Larger, more prominent font size */
    color: var(--secondary-color); /* Dark grey color for better readability */
    margin-top: 1rem; /* Spacing above the app name */
    font-weight: 600; /* Semi-bold font weight */
}

.appDescription {
    color: var(--secondary-color); /* Lighter grey color */
    font-size: 1rem; /* Standard font size */
    margin: 1rem 0; /* Vertical spacing for breathing room */
    height: 3rem; /* Fixed height to ensure uniformity across slides */
    overflow: hidden; /* Hides overflow to maintain a clean look */
    text-overflow: ellipsis; /* Adds an ellipsis to text that overflows */
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px; /* Reduced gap for a more modern look */
    margin-top: 1rem; /* Adds some space above the buttons */
}



.app-card-pricing{
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-size: 1.5rem; 
    border: 1px solid;
    padding: 20px;
    border-radius: 5rem;
    margin: 5%;
    margin-top: 0%;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Shadow for send button */
}
.app-card-pricing:hover{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}
.goToAppButton:hover {
    background-color: #1e7e34; /* Darkens the color on hover */
}
