/* :root {
    --primary-color: #20569B; 
    --secondary-color: #f4f4f4;
    --tertiary-color: #000000; 
    --quaternary-color: #00FF00;
    --quinary-color: #00FFFF;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  } */

.cq-main-container {
    position: sticky;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--primary-color);
    /* Different color for the main app background */
    padding: 0;

    /* Add some space around the entire app */
}

.cq-message-history-container {
    
    top: 0; 
    height: 70vh;
    overflow-y: scroll;
    margin: 10px;
    padding: 15px;
    border: 2px solid  var(--primary-color);
    background-color: var(--secondary-color);
    border-radius: 10px;
}
.student {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    padding: 10px;
    border-radius: 20px;}

.teacher {
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
    border: none;
    padding: 5px;
    border-radius: 20px;}
    
.cq-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cq-message-bubble {
    padding: 0;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
}

.text {
    color:  var(--tertiary-color);
    padding: 0px;
    font-family: 'Arial';
}

.cq-user-interface-container {
    position: relative;
    bottom: 0;
    left: 3px;
    width: 96.5%;
    min-height: 80px;
    height: 10vh;
    margin: 10px;
    padding: 10px;
    border: 2px solid #c0c0c0;
    background-color: #f8f9fa;
    border-radius: 10px;
    z-index: 2; 
}
.cq-auto-options {
    display: flex;
    flex-wrap: wrap; /* Allow the items to wrap to the next line */
    gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
    align-items: center; /* Align items vertically */
}


.cq-option {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    text-align: center;
    white-space: nowrap; 
    max-width: 78vw; 
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px; 
    flex-shrink: 0;
    flex-basis: auto; /* Initial width auto */
    margin-right: 10px; /* Space between bubbles */
  }


.cq-option:last-child {
    margin-right: 0; /* Remove right margin from the last bubble */
}

.cq-option:hover {
    background-color: var(--tertiary-color);
    color: var(--quaternary-color);
    transform: scale(1.1);
}


.cq-user-input-container{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

  
  .cq-user-input-container input {
    flex-grow: 1;
    padding: 10px;
    background-color: var(--secondary-color);
    border: 1px solid var(--tertiary-color);
    border-radius: 5px;
  }
  
  .cq-submit-button {
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Shadow for send button */
  }
  .cq-submit-button:hover {
    border:1px solid var(--quaternary-color);
    background-color: var(--tertiary-color);
    color: var(--quaternary-color);
    
  }
  
  .cq-submit-button:disabled {
    background-color: #cccccc; /* dull color indicating disabled state */
    color: #666666;
    cursor: not-allowed; /* cursor indicating the item is not interactive */
  }
  
  @keyframes blink {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
  }
  
  .typing-indicator-message-bubble {
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);;
    border-radius: 20px;
    display: inline-block;
    max-width: 200px;
  }
  
  .typing-indicator {
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
  }
  
  .typing-indicator-dot {
    position: absolute;
    width: 0.2em;
    height: 0.2em;
    background-color: var(--primary-color);
    border-radius: 50%;
    bottom: 0;
    animation: blink 1.4s infinite both;
  }
  
  .typing-indicator-dot:nth-child(1) {
    left: 0.1em;
    animation-delay: 0.3s;
  }
  
  .typing-indicator-dot:nth-child(2) {
    left: 0.5em;
    animation-delay: 0.6s;
  }
  
  .typing-indicator-dot:nth-child(3) {
    left: 0.9em;
    animation-delay: 0.9s;
  }

  @media (max-width: 600px) {
    /* .cq-main-container {
        height: 100%;
        
    } */
    .cq-option {
        background-color: var(--primary-color);
        color: var(--secondary-color);
        border: none;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        text-align: left;
        white-space: normal; /* Allow text to wrap */
        max-width: 78vw;
        overflow-wrap: break-word; /* If there is a long word, this will break it to prevent overflow */
        text-overflow: ellipsis; /* If you still want to use ellipsis, you might need a fixed height */
        min-width: 100px; /* Minimum width of the bubble */
        flex-shrink: 0; /* Prevent bubble from shrinking */
        flex-basis: auto; /* Initial width auto */
        margin-right: 10px; /* Space be*/
      }
   

    .cq-user-interface-container {

        bottom: 0;
        left: 3px;
        width: 87%;
        height: 10vh;
        margin: 10px;
        margin-bottom: 50px;
        padding: 10px;
        min-height: 42px;
        border: 2px solid #c0c0c0;
        background-color: #f8f9fa;
        border-radius: 10px;
        z-index: 5; 
    }
}