@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;600&display=swap');

.header {
    font-family: 'Merriweather', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    padding: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    color: var(--secondary-color);
    text-transform: lowercase;
    position: sticky;
  top: 0; /* Set the top edge of the element to be stuck to the top edge of the viewport */
  z-index: 1000; /* Ensure the header is above other content; may adjust as needed */

}

.header-content {
    max-width: 100vw;
    /* Adjust to your max width preference */
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-domain{
    background-color: var(--primary-color);
    color: var(--secondary-color)

}

.header-domain:hover {
    /* background-color: var(--secondary-color); */
    color: var(--tertiary-color);
    cursor: default;
}


.logo {
    width: 50px;
    background-color: var(--primary-color);
    height: 50px;
    background-image: url('/public/logo.png');
    background-size: cover;
    border: 1px solid var(--primary-color);
    border-radius: 5px;

}


.logo:hover {
    border: 1px solid var(--tertiary-color);
    background-color: var(--secondary-color);

}

.nav {
    justify-self: end;
    
}

.nav-list {
    list-style: none;
    padding: 5px;
    margin: 0;
    border-radius: 10px;
    /* Rounded corners */
    background-color: var(--primary-color);
}



.nav-item {
    display: inline-block;
    margin-right: 20px;
}

.nav-link {
    text-decoration: none;
    color: var(--secondary-color);
}