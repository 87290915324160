@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.message-content-left.animated,
.message-content-right.animated {
    animation: fadeIn 0.5s forwards;
    opacity: 0;
}
.loader {
  border: 6px solid var(--primary-color); /* Light grey */
  border-top: 6px solid var(--tertiary-color); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.conversation-container {
    background-color: var(--tertiary-color);
    
    padding:0.4rem;
  }

  .min-height {
    min-height: 75vh;
  }
  
  .message-left,
  .message-right {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .message-right {
    justify-content: flex-end;
  }
  
  .message-content-left,
  .message-content-right {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
  }
  
  
  .message-content-left {
    background: var(--tertiary-color);
    color: var(--quaternary-color);
  }
  
  .message-content-right {
    background: var(--tertiary-color);
    color: var(--quinary-color);
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.button-container {
  padding: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
  background-color: var(--primary-color);
  text-transform: lowercase; /* All text to lowercase */
  display: flex;
  justify-content: center;
  gap: 10px;   
}


.button-class-conversation {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  font-weight: bold;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  min-height: 2.2rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  display: inline-block;
  width: 8rem;
}

.button-class-conversation:hover {
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  border:1px solid var(--quaternary-color);
}
.button-class-conversation:disabled {
  background-color: grey;
  color: white;
  border:1px solid grey;
  cursor: not-allowed;
}


.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 90%;  /* Position it above the button */
  left: 5%;
  margin-left: -00px;  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.button-class-conversation:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}