
.signininput {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    color: var(--secondary-color);
    background-color: var(--tertiary-color);
    border-radius: 5px;
    box-sizing: border-box; 
  }

.signininput:focus {
    border: 1px solid var(--secondary-color);
}


.login-signup-text {
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
}


.signup-login-button{
    font-weight: bold;
      margin-left: .2rem;
      margin-right: .1rem;
      margin-bottom: 1.5rem;
      min-height: 1rem;
      padding: .3rem;
      margin: .3rem;
  }

  .signin-links {

    color:var(--quaternary-color);
   

  }

  .signin-links:visited{

    color: var(--quinary-color);
  }