/* .user-profile {
    font-family: Arial, sans-serif;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
} */
/* Base styles for user-profile */
.user-profile {
    /* Shared styles for both mobile and web */
    display: block;
    margin: auto;
    border: 1px solid #ddd; /* Example styling, can be customized */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example styling, can be customized */
}

/* Mobile styles */
@media screen and (max-width: 600px) {
    .user-profile {
        width: 90vw; /* Takes up most of the screen */
        height: 75vh; /* Adjust height automatically */
        /* Additional mobile-specific styles can be added here */
    }
}

/* Web/Desktop styles */
@media screen and (min-width: 601px) {
    .user-profile {
        width: 375px; /* Approximate width of a phone screen */
        height: 667px; /* Approximate height of a phone screen */
        /* Additional web-specific styles can be added here */
    }
}
.user-profile-header {
    display: flex;
    align-items: center;
    margin: 5vh 1vw 3vh 1vw;
}

.user-icon {
    background-color: var(--primary-color);
    background-image: url('/public/profile_icon.png');
    font-size: 140px;
    
}

.user-name {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
}

.content .section {
    margin-bottom: 15px;
}

.content .section label {
    font-weight: bold;
}

.content .section span, .content .section input {
    margin-left: 10px;
    margin-right: 10px;
}

.content .section input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.user-profile-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid;
    padding: 10px;
    border-radius: 5rem;
    margin-right: 5%;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    /* Shadow for send button */
}

.user-profile-button:hover {
    border: 1px solid var(--primary-color);
    background-color: var(--secondary-color);
    color: var(--primary-color);

}