/* 
.emailSignup {
    width: 80%;
    margin: 20px auto;
    color: var(--secondary-color);
} 


label {
    font-weight: bold;
    margin-right: 1em;
    color: var(--primary-color);
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--quaternary-color);
    color: var(--quaternary-color);
    background-color: var(--tertiary-color);
    border-radius: 5px;
    box-sizing: border-box; 
  }

input[type="email"]:focus {
    border-color: var(--primary-color);
}

.button-emaillist {
  font-weight: bold;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    background-color: var(--primary-color); 
    color: var(--secondary-color);
    border-radius: 5px;
    border: none;
    min-height: 2.2rem;
    margin-right: 1rem;
    padding: 1rem;
  }
  
.button-emaillist:hover {
    background-color: var(--tertiary-color); 
    color: var(--quaternary-color);
    border:1px solid var(--quaternary-color);
  }
.emailP {
    color: var(--primary-color);
    font-weight: bold;
    text-align: center;
    margin-top: 1em;
} */

.emailSignUp {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: var(--tertiary-color);
}

.emailSignUp h2 {
  margin-bottom: 20px;
  
  color: var(--secondary-color);
}

.emailSignUp input[type="email"] {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid  var(--secondary-color);
  border-radius: 5px;
}

.button-emaillist {
  font-weight: bold;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    background-color: var(--primary-color); 
    color: var(--secondary-color);
    border-radius: 5px;
    border: none;
    min-height: 2.2rem;
    margin-right: 1rem;
    padding: 1rem;
  }
  
.button-emaillist:hover {
    background-color: var(--secondary-color); 
    color: var(--primary-color);
    border:1px solid var(--secondary-color);
  }
.toast {
  margin-top: 20px;
  padding: 10px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
  width: fit-content;
  margin: 20px auto;
}


.subscription-text{
  color:var(--secondary-color);
}