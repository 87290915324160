@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;600&display=swap');


.button-container-default {
    display: flex;
    align-items: center;
    padding: left 10rem;
  }

.buttonClass1 {
  font-weight: bold;
  font-family: 'Merriweather', sans-serif;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 5px;
}

.buttonClass1:hover {
    background-color: var(--tertiary-color);
    color: var(--quaternary-color);
    border:1px solid var(--quaternary-color);
}

.buttonClass2 {
  font-weight: bold;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.buttonClass2:hover {
    background-color: var(--tertiary-color);
    color: var(--quaternary-color);
    border:1px solid var(--quaternary-color);
}



  
.buttonClass3 {
  font-weight: bold;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 5px;
    border:1px solid var(--secondary-color);
    min-height: 2.2rem;
    margin-right: 1rem;
    padding: 1rem;
    font-weight: bold;
}

.buttonClass3:hover {
    background-color: var(--tertiary-color);
    color: var(--quaternary-color);
    border:1px solid var(--quaternary-color);
}

.buttonClass3:disabled {
    background-color: var(--disabled-bg-color);  /* Replace with your actual color variable */
    color: var(--disabled-text-color);  /* Replace with your actual color variable */
    cursor: not-allowed;  /* Changes cursor to indicate the button is disabled */
}



.formcontainer1 {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Changed from 'top' to 'flex-start' for proper flexbox alignment */
  /* height: 100vh; */
  background-color: var(--primary-color);
  padding: 0.3125rem; /* Converted from 5px */
  margin: 1rem;
  border-radius: 0.25rem;
    /* display: flex;
  justify-content: center;
  align-items: center;
    max-width: 99%;
    padding: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* margin: 1px;
    background-color: var(--primary-color);
    border-radius: 5px;
    /* overflow: hidden; */
    /* margin-top: 10px;
    margin-bottom: 10px; */ 
}
.formcontainer2 {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  background-color: var(--primary-color);
  align-items: center;
  padding: 0.3125rem; /* Converted from 20px */
  
  width: 90%;
  max-width: 25rem;
  /* max-width: 400px; */
  
  /* min-width: 25rem; */
  /* padding: 5px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* margin: 1px; */

  /* border-radius: 5px; */
  /* overflow: hidden; */
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}

.header1 {
    color: var(--secondary-color);
    text-align: center;
    margin-bottom: 10px;
}
/* Amplify Sign In Overrides */
/* 
.amplify-flex {
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    width: 95%;
    margin: 20px auto;
    color: var(--secondary-color);
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-color, #f0f0f0);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;

    
  }
  .amplify-flex.amplify-field.amplify-textfield 
  {
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    width: 95%;
    margin: 20px auto;
    color: var(--secondary-color);
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-color, #f0f0f0);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.amplify-tabs-item {
    background-color: var(--secondary-color); 
    color: var(--primary-color);
    border-radius: 5px;
}

.amplify-tabs-item:hover {
    background-color: var(--tertiary-color); 
    color: var(--quaternary-color);
    font-weight: bold;
}

.amplify-button{
    background-color: var(--secondary-color); 
    color: var(--primary-color);
    border-radius: 5px;
}


.amplify-button:hover{
    background-color: var(--secondary-color); 
    color: var(--primary-color);
    border-radius: 5px;
}

.amplify-label{
    color: var(--secondary-color);
}
 */

.agreement-container {
    text-align: left;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-left: 0;
    padding-bottom: 1rem;
}

.agreement-checkbox {
    /* margin-left: 0;
    margin-right: 8px; */
    margin-top: auto;  /* Push to the bottom of the flex container */
    margin-bottom: auto
}

.agreement-label {
    margin-left: 1rem;
    display: inline-block;
    color: var(--secondary-color);
    font-size: 1.5vh;
}



/* Your existing styles */

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 34px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color:  var(--secondary-color);
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: var(--primary-color);
  }
  
  input:checked + .slider:before {
    transform: translateX(86px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .stripe-card {
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    width: 99%;
    padding: 5px;
    margin: 1px;
    background-color: var(--secondary-color);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.inner-card {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  max-width: 500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.membership-product {
  color: var(--secondary-color);
}
