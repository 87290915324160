
/* :root {
    --primary-color: #0D3971; 
    --secondary-color: #FFFFFF;
    --tertiary-color: #000000; 
    --quaternary-color: #00FF00;
    --quinary-color: #00FFFF;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;

  } */
  
  p{
    color: var(--secondary-color)
  }
  
  footer {
    background-color: var(--tertiary-color); /* Tertiary color as background */
    color: var(--secondary-color);
    z-index: 0; 
    position: relative; 
    padding:10px;
  }
  
  footer .contact-info,
  footer .social-media-links,
  footer .legal-info {
    margin-bottom: 15px;
  }
  
  footer .legal-info a {
    margin-right: 10px;
    text-decoration: none;
    color: var(--secondary-color); /* Secondary color for links */
  }

.footer-socialmedia{
  padding:1rem;
}



