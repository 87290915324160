.pricing-container {
 
    color: var(--primary-color);
    background-color: var(--secondary-color);
  }
  

.pricing-section {
    text-align: center;
    padding: 20px;
    background-color: var(--secondary-color); /* Off white background */
    color: var(--tertiary-color); /* Black text */
}

.pricing-heading {
    color: var(--primary-color); /* Dark blue */
    font-size: 2em;
}

.pricing-subheading {
    font-size: 1.2em;
    margin-bottom: 30px;
    color: var(--primary-color); 
}

.pricing-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    
}
.pricing-card-title {
    color: var(--secondary-color);
    font-size: 2em;
}
.pricing-card {
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 90%; /* Adjust based on your preference */
    max-width: 300px; /* Maximum width */
    margin-bottom: 20px;
}

.pricing-card h2 {
    color: var(--primary-color);
    font-size: 1.5em;
    margin-bottom: 10px;
}

.pricing-card p {
    font-size: 1em;
    margin-bottom: 5px;
}

@media (min-width: 768px) {
    .pricing-cards {
        flex-direction: row;
        justify-content: center;
    }
}
/* Add to the existing CSS */
.promo-offer {
    background-color: var(--primary-color); 
    color: var(--primary-color); 
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0 30px;
    font-weight: bold;
}



.pricing-form {
    background-color: var(--secondary-color); 
    color: var(--primary-color); 
    max-width: 300px;
    margin: auto;
    padding: 20px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;

}

.pricing-dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pricing-dropdown-select {
    padding: 8px;
    margin: 0 112px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    width: 60px;
    
}
 .pricing-submit-button {
    padding: 8px 12px;
    border-radius: 4px;

    border: 1px solid var(--primary-color);
    
}

.pricing-submit-button {

    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}

.pricing-submit-button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.pricing-description-text, .pricing-total {
    font-size: 0.9em;
    color: var(--tertiary-color);
}

/* Adjust label style */
.pricing-dropdown-label {
    font-weight: bold;
}

.crossed-out {
    text-decoration: line-through;
}

.pricing-tryit {

        background-color: var(--secondary-color);
        color: var(--primary-color);
        font-size: 1.5rem; 
        border: 1px solid;
        padding: 20px;
        border-radius: 5rem;
        margin: 1rem;
        cursor: pointer;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Shadow for send button */

}

.pricing-tryit:hover{
    background-color: var(--primary-color);
    color: var(--secondary-color);
}