
:root {
  --primary-color: #0D3971; 
  --secondary-color:  #f4f4f4;
  --tertiary-color: #000000; 
  --quaternary-color: #00FF00;
  --quinary-color: #00FFFF;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}

:root-backup-delete {
  --primary-color: #20569B; 
  --secondary-color: #fafafa; /* Light grey for high contrast with primary color */
  --tertiary-color: #303030; /* Dark grey for readability and contrast */
  --quaternary-color: #D64545; /* Muted red for a noticeable yet harmonious contrast */
  --quinary-color: #509C82; /* Green that offers a contrast and complements the other colors */
}

body {
  margin: 0;
  font-family: 'Merriweather', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


