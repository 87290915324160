.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; 
  }
  .modal-button{
    font-weight: bold;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    margin:10px;
    border-radius: 5px;
    border:1px solid var(--secondary-color);
    cursor: pointer;
    transition: 0.3s;
    min-height: 2.2rem;
    margin-right: 1rem;
    width: 6rem;
    padding: 1rem;
    position: relative;
    text-align: center;
    display: inline-block;
    white-space: nowrap;

  }

  .modal-content {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 20px;
    border-radius: 4px;
    width: 300px;
    text-align: center;
    position: relative;
  }
  .modal-content p {
    color: var(--secondary-color);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-button-container {
    background-color: var(--primary-color);
    margin-top: 20px;
    
  }

