.courthouse-container {
    display: flex;
    flex-direction: column;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    background-color: var(--secondary-color);
    font-size: 16px;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}


@media (max-width: 768px) {
    .courthouse-container {
        padding-left: 1vw;
        padding-right: 1vw;
        margin-left: 0.5vw;
        margin-right: 0.5vw;
    }
}


@media (min-width: 1024px) {
    .courthouse-container {
        justify-content: space-between;
        padding-left: 3vw;
        padding-right: 3vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}

.courthouse-heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;

    color: var(--primary-color);
}

.courthouse-subtitle {
    font-size: 1rem;
    text-align: center;
    /* padding: .5vh; */
    color: var(--primary-color);
    font-style: italic;
}

.courthouse-disclaimer {
    font-size: 1.25rem;
    text-align: center;
    padding: .5vh;
    color: var(--tertiary-color);
    padding-top: 2vh;
}


.courthouse-accordion {
    width: auto;
    padding-top: 2vh;
}

.courthouse-accordion-item {
    margin-bottom: 2vh;

}

.courthouse-accordion-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1%;
    padding-left: 2%;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: .4rem;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
}

.courthouse-accordion-header:hover {
    background-color: var(--primary-color);
}


.courthouse-accordion-content {
    background-color: var(--secondary-color);
    max-height: 0vh;
    margin: 1vw;
    overflow: hidden;
    transition: max-height 0.3s;
    white-space: pre-wrap;
    overflow: auto;
}

.courthouse-accordion-item.open .courthouse-accordion-content {
    height: auto;
    max-height: 150vh;
}

.courthouse-accordion-item.disabled .courthouse-accordion-header {
    background-color: gray;
}



.courthouse-accordion-header-title {
    white-space: nowrap;
    z-index: 10;

}

.courthouse-chevron {
    margin-left: auto;
    font-size: 2rem;
    /* Adjust size as needed */
    font-weight: bold;
    /* Make the symbol bold */
    transition: transform 0.3s ease;
    /* Smooth transition for rotation */
}

.courthouse-progress-bar-container {
    position: absolute;
    margin-left: 35vw;
    width: 45%;
    right: 10%;
    height: .9rem;
    border-radius: 5rem;
    background-color: #d3d3d3;
    vertical-align: middle;
    border: 1px solid var(--tertiary-color);
    z-index: 1;

}

.courthouse-progress-bar {
    z-index: 1;
    height: 100%;
    border-radius: 5rem;
    background-color: var(--quaternary-color);
    border-color: var(--quaternary-color);
    transition: width 0.5s ease;
}

.courthouse-progress-check {
    display: none;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    /* background-image: url('/path/to/green-check-icon.svg'); */
    background-size: cover;
}

/* TBD State */
.courthouse-state-tbd .courthouse-progress-bar {
    background-color: black;
    animation: courthouse-flashing 1s infinite;
}

/* Empty State */
.courthouse-state-empty .courthouse-progress-bar {
    width: 0;
}

/* Empty State */
.courthouse-state-ready .courthouse-progress-bar {
    width: 0;
}

/* In Progress State */
.courthouse-state-inprogress .courthouse-progress-bar {
    width: 80%;
    /* This can be dynamically adjusted */
    animation: courthouse-filling 60s infinite;
}

/* Complete State */
.courthouse-state-complete .courthouse-progress-bar {
    width: 100%;
}

.courthouse-state-complete .courthouse-progress-check {
    display: block;
}

@keyframes courthouse-flashing {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@keyframes courthouse-filling {

    0%,
    100% {
        width: 75%;
    }

    50% {
        width: 100%;
    }
}

.credits-display{
    background-color: white;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 10px;
    width: 25px;
    border-radius: .2rem;
    text-align: center;
    margin-left:20px;
    /* cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
}
.courthouse-button-group {
    margin-top: 2vh;
    display: flex;
    justify-content: left;
    gap: 4rem;
}

.courthouse-submit-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid;
    padding: 10px;
    border-radius: 5rem;
    margin-right: 5%;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    /* Shadow for send button */
}

.courthouse-submit-button:hover {
    border: 1px solid var(--primary-color);
    background-color: var(--secondary-color);
    color: var(--primary-color);

}







.courthouse-input-group {
    margin-bottom: 2vh;
}


.courthouse-input-label {
    display: block;
    margin-bottom: 5px;
    color: var(--primary-color);
}

.courthouse-form-textarea-input {
    width: 94%;
    padding: 2%;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    min-height: 100px;
    resize: vertical;
}

.courthouse-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid;
    padding: 10px;
    border-radius: 5rem;
    margin-right: 5%;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    /* Shadow for send button */
}

.courthouse-comingsoon {
    font-size: .5rem;

}

.courthouse-button:hover {
    background-color: #0053ba;
    /* Darken the color */
}

.courthouse-button:disabled {
    background-color: grey;
    color: white;
    border: 1px solid grey;
    cursor: not-allowed;
}

.courthouse-nav {
    padding-top: 3%;
}

.nav-list {
    background-color: var(--secondary-color);
    /* display: flex; */
    /* Aligns items in a row */
    padding: 0;
    /* Removes default padding */
    margin: 0;
    /* Removes default margin */
    list-style-type: none;
    /* Removes default list bullets */
    font-family: Arial, sans-serif;
    display: inline-flex;
    display: flex;
    justify-content: center; /* Horizontally center the buttons in the container */
    align-items: center; /* Vertically center the buttons in the container */
    gap: 10px;
    
    background-color: var(--secondary-color);
    font-size: 16px;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}

.nav-item {
    padding: 10px 20px;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--secondary-color);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); /* Shadow for unpressed state */
  position: relative; 
  border-radius: 10px;
  width:200px;
}

/* .nav-item:not(:last-child)::after {
    content: "|";
    color: #000;


} */

.nav-item:hover {
    text-decoration: none;
    /* No text decoration on hover */
}

.active {
    background-color: #ddd;
    border-style: inset;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1); /* Inner shadow for depressed state */
    transform: translateY(2px); /* Optional: Slightly move the button down */
    font-style: bold;
}


.courthouse-dropdown-container {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    /* background-color: #f0f0f0; */
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    text-align: left; 
}

.courthouse-dropdown-select {
    width: auto; /* Width is determined by content */
    max-width: 150px; /* Maximum width */
    padding: 10px;
    margin-right: 20px; /* Add some space between the dropdown and the text */
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    border: 1px solid var(--primary-color);
}

.courthouse-dropdown-select:disabled {
    /* background-color: #e9e9e9; */
    cursor: default;
    margin-right: 40px;
}

.courthouse-description-text {
    flex-grow: 1; /* Allows the description text to fill the remaining space */
    margin-left: 20px; /* Add some space between the text and the dropdown */
    color: #333;
    font-size: 0.9em;
    line-height: 1.4;
}



.toggle-switch {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.toggle-switch input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-switch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-switch label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggle-switch input:checked + label {
    background: #4cd137;
}

.toggle-switch input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-switch label:active:after {
    width: 45px;
}

