
  .profile-dropdown {
    position: relative;
    
  }
  
  .profile-dropdown-toggle {
    cursor: pointer;
  }
  
  .profile-text {
    display: none;
  }
  
  .profile-icon {
    font-size: 24px;
    background-image: url('/public/profile_icon.png');
  }
  
  .profile-dropdown-menu {
    position: absolute;
    right: 0;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px var(--tertiary-color);
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 150px; 
    z-index: 1;
  }

  .profile-dropdown-menu-list{
    padding: 0;
    margin: 0;

  }
  .profile-dropdown-label{

    background-color:var(--secondary-color);
    color: var(--primary-color);
  }

  .profile-dropdown-item:hover{

    cursor: pointer;
    background-color:var(--primary-color);
    color:var(--secondary-color);
    text-decoration: underline;
  }
  .profile-dropdown-item:hover .profile-dropdown-label{
    background-color:var(--primary-color);
    color: var(--secondary-color);
    cursor: pointer;
    text-decoration: underline;
  }

  .profile-dropdown-item {
    padding: 1vh 1vw;
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    text-transform: none;
    background-color:var(--secondary-color);
  }

  /* .profile-dropdown-item:hover {
    
  } */

  
  @media screen and (min-width: 768px) {
    .profile-text {
      display: inline;
    }
    .menu-icons {
        display: inline;
      }
      .profile-dropdown-item  label {
        display: none;
      }
  }


@media only screen and (min-width: 768px) {
    .menu-icons {
      display: inline;
    }
    .profile-dropdown-item  label  {
      display: inline;
    }
  }


  .profile-icon {
    width: 2rem;
    height: 2rem;
    
    background-size: cover;
    
    border-radius: 5px;
    border:1px solid var(--secondary-color);
    
    
  }


  .profile-icon:hover {
    border:1px solid var(--tertiary-color);
    
  }
