.landing-container {
  background-color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  
}


h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
}

h3 {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1.5rem;
  color: var(--primary-color);
  border-bottom: 1px solid var(--tertiary-color);
}

.landing-section {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1.5rem;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-bottom: 2px solid #000000;

}


.centering-container.card-style {
  border-radius: 10px;
  width: 96%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--secondary-color);
  color: var(--primary-color);

  margin: 2% 2%;
  padding: 3% 0% 0% 0%;

}

.centering-container.card-style p {
  padding: 0% 0% 0% 5%;
}

.carousel-container {
  border-radius: 10px;
  width: 96vw;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  margin-bottom: 3vh;
  
}

.landing-feedback{
  max-width: 94vw;
  /* border: 2px solid var(--primary-color);
  border-radius: 10px; */
  margin: 20px auto;
  padding: 20px;
}
.landing-pricing{
  max-width: 94vw;
  border: 2px solid var(--tertiary-color);
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
  border-radius: 10px;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
}
.bmc-container {
  /* max-width: 140px; */
  max-height: 1.5rem;
  padding-left: 1rem;

}

@media screen and (min-width: 768px) {
  .landing-content h1 {
    font-size: 3rem;
  }

  .landing-content p {
    font-size: 1.5rem;
  }

  .landing-feedback{
    max-width: 50vw;
  }
  .landing-pricing{
    max-width: 50vw;
  }
}

html {
  scroll-behavior: smooth;
}