.create-convo-form {
  width: 95%;
  margin: 20px auto;
  color: var(--primary-color);
}

.card {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  width: 99%;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1px;
  background-color: var(--primary-color);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card h2 {
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 10px;
}

.card label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--secondary-color);
}

.card input {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--quaternary-color);
  color: var(--quaternary-color);
  background-color: var(--tertiary-color);
  border-radius: 5px;
  box-sizing: border-box;
  /* Add this line */
}

.chatbotDefinition {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--quaternary-color);
  color: var(--quaternary-color);
  background-color: var(--tertiary-color);
  border-radius: 5px;
  box-sizing: border-box;
  /* Add this line */
  resize: vertical;
  vertical-align: top;
  line-height: 1.6;
  overflow: auto;
}

.button-container-create-convo {



  display: flex;
  justify-content: center;
  gap: 1rem;
}


.button-class-create-convo {
  font-weight: bold;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  margin: 10px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  transition: 0.3s;
  min-height: 2.2rem;
  margin-right: 1rem;
  width: 8rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  display: inline-block;
}

.button-class-create-convo:hover {
  border: 1px solid var(--quaternary-color);
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
}

.button-class-create-convo:disabled {
  background-color: grey;
  color: white;
  border: 1px solid grey;
  cursor: not-allowed;
}


.tooltip-text-create-convo {
  visibility: hidden;
  width: 120px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  /* Position it above the button */
  left: 5%;
  margin-left: -00px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.button-class-create-convo:hover .tooltip-text-create-convo {
  visibility: visible;
  opacity: 1;
}